import { createStore } from 'zustand/vanilla'
import { persist, devtools } from 'zustand/middleware'

export interface AuthStore {
    accessToken: string
    refreshToken: string
    setRegistrationDetails: (details) => void
    registrationDetails: {}
    setAccessToken: (accessToken: string) => void
    setRefreshToken: (refreshToken: string) => void
    resetAuthState: () => void
    setCurrentComplex: (currentPoolComplex: string) => void
    currentPoolComplex: string
}

const initialAuthState: AuthStore = {
    setRegistrationDetails: () => {},
    registrationDetails: {},
    accessToken: '',
    refreshToken: '',
    setAccessToken: () => {},
    setRefreshToken: () => {},
    resetAuthState: () => {},
    setCurrentComplex: () => {},
    currentPoolComplex: '',
}

export const globalState = createStore<
    AuthStore,
    [['zustand/devtools', never], ['zustand/persist', AuthStore]]
>(
    devtools(
        persist(
            (set) => ({
                ...initialAuthState,
                setAccessToken: (accessToken: string) => set({ accessToken }),
                setRefreshToken: (refreshToken: string) =>
                    set({ refreshToken }),
                resetAuthState: () => set(initialAuthState),
                setCurrentComplex: (currentPoolComplex) =>
                    set({ currentPoolComplex }),
                setRegistrationDetails: (registrationDetails) =>
                    set({ registrationDetails }),
            }),
            { name: 'globalState' }
        )
    )
)
export function getCurrentBranchId() {
    return globalState.getState().currentPoolComplex || ''
}
export default globalState
